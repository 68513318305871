import React from 'react';
import usePromise from 'react-promise';
import { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { Chip } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { getDataFromApiCall, preloadImage } from '../utils'

import ProjectCardBig from './projectCardBig';
import ProjectCardSmall from './projectCardSmall';
import NavButton from './navButton';
import NavBar from './navBar';
import AboutUnit from './aboutUnit';
import Project from './project';
import SmallCardsAlbum from './smallCardsAlbum';
import BigCardsAlbum from './bigCardsAlbum';
import TagsPanel from './tagsPanel';



import ReactMarkdown from 'react-markdown';
import { AddBoxSharp } from '@material-ui/icons';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="portfolioreactfront.herokuapp.com">
                Eugene Kuklin
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    cardGridBig: {
        paddingTop: theme.spacing(11),
        paddingBottom: theme.spacing(7),
    },
    cardGridSmall: {
        paddingTop: theme.spacing(7),
        paddingBottom: theme.spacing(0),
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),

        borderTop: `1px solid ${theme.palette.divider}`
    },
    cardAbout: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
    },

    projectConteiner: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
    },
    main: {
    },
    tagsPanel: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(3),
    },
    smallCardsAlbum: {
        paddingTop: theme.spacing(7),
    },
}));

export function getBackEndLink() {
    const BACKEND_LINK = "https://portfolio-django-back.onrender.com/" // "https://portfoliodjangoback.herokuapp.com/" // "http://127.0.0.1:8000/"
    return (BACKEND_LINK);
}

const BACKEND_LINK = getBackEndLink()

const album_api_call_link = BACKEND_LINK + "api/projects/list/?expand=tags,images,cover&fields=id,name,priority,cover,cover.image,cover.image_100,cover.image_200,cover.image_500,cover.image_1000,short_description,tags.id,tags.name,tags.name_short,tags.icon,tags.show_name,tags.show_icon,tags.priority,images.id,images.name,images.image,images.placeholder"
let albumDataPromise = getDataFromApiCall(album_api_call_link)

const tags_link = BACKEND_LINK + "api/tags/list/"
let tagsDataPromise = getDataFromApiCall(tags_link)

const industries_link = BACKEND_LINK + "api/industries/list/"
let industriesDataPromise = getDataFromApiCall(industries_link)


const project_api_call_link = "api/projects/detail/#{project_id}/?expand=tags,images_gallery,images_other"


var hashOld = window.location.hash;

const aboutBlockName = "about"
const projectsListBlockName = "projects"
const projectBlockName = "project"

export default function Portfolio() {


    const classes = useStyles();

    // Transit animation handling //
    const fadeTime = 1000

    const [blockTransition, setBlockTransition] = React.useState(null);
    const [currentBlockName, setCurrentBlockName] = React.useState(null);
    const handleBlockTransition = (nextBlockName, reload = false) => {
        //console.log("handleBlockTransition", currentBlockName, "=>", nextBlockName)
        if (reload === true || nextBlockName !== currentBlockName) {
            setCurrentBlockName(null)
            setBlockTransition(nextBlockName)
        }
    };

    const onBlockFadeExited = () => {
        //console.log("onBlockFadeExited", nextFilteringTagId)
        setCurrentBlockName(blockTransition)
        setBlockTransition(null)
        setCurrentFilteringTagId(nextFilteringTagId)
    }

    // Routing //
    const toAbout = () => {
        handleBlockTransition(aboutBlockName)
        window.location.hash = aboutBlockName;
    };
    const toProjects = () => {
        handleBlockTransition(projectsListBlockName)
        window.location.hash = projectsListBlockName;
    };

    const [currentProjectId, setCurrentProjectId] = React.useState(null);
    const toProject = (id) => {
        setCurrentProjectId(id)
        handleBlockTransition(projectBlockName)
        window.location.hash = projectsListBlockName + "/" + id
    };

    function checkRoute() {
        return (window.location.hash !== hashOld && currentBlockName !== null)
    }
    function makeRoute() {
        //console.log("User went back or forward to application state represented by " + hashOld);
        var newBlockName = window.location.hash.slice(1)
        var id_ = null
        if (newBlockName.includes(projectsListBlockName + "/")) {
            id_ = newBlockName.split("/")[1];
            setCurrentProjectId(id_)
            newBlockName = projectBlockName
        }
        hashOld = window.location.hash
        handleBlockTransition(newBlockName, true)
        //console.log(id_)
        //window.location.hash = aboutBlockName;

    }


    setInterval(function () {
        if (checkRoute()) {
            makeRoute()
        }
    }, 5);

    // TAG FILERING //
    const showAllTagId = 1 // TODO to db
    const [nextFilteringTagId, setNextFilteringTagId] = React.useState(showAllTagId);
    const [currentFilteringTagId, setCurrentFilteringTagId] = React.useState(showAllTagId);
    const filterProjectsByTagStartTransition = (tag_id) => {
        if (tag_id === currentFilteringTagId) {
            return;
        }
        setNextFilteringTagId(tag_id)
    };
    const onfilterProjectsByTagTransitionExited = () => {
        setCurrentFilteringTagId(nextFilteringTagId)
    }







    const [appIsInit, setAppIsInit] = React.useState(false);
    if (appIsInit === false) {
        if (window.location.hash === "") {
            window.location.hash = aboutBlockName
        }
        //makeRoute()

        var initBlockName = window.location.hash.slice(1)
        var id_ = null
        if (initBlockName.includes(projectsListBlockName + "/")) {
            id_ = initBlockName.split("/")[1];
            setCurrentProjectId(id_)
            initBlockName = projectBlockName
            //toProject(id_)
        }

        if (true) {
            setCurrentBlockName(initBlockName)
            setAppIsInit(true)
        }
    }
    /////////////////////////////////

    // TODO in one place
    const [matches, setmatches] = useState(window.matchMedia("(min-width: 1000px)").matches);
    useEffect(() => {
        const handler = e => setmatches({ matches: e.matches });
        window.matchMedia("(min-width: 1000px)").addListener(handler);
    });



    // function makeRoute() {
    //     console.log("User went back or forward to application state represented by " + hashOld);
    //     var newBlockName = window.location.hash.slice(1)
    //     var id_ = null
    //     if (newBlockName.includes(projectsListBlockName + "/")) {
    //         id_ = newBlockName.split("/")[1];
    //         setCurrentProjectId(id_)
    //         newBlockName = projectBlockName
    //     }
    //     hashOld = window.location.hash
    //     handleBlockTransition(newBlockName, true)
    //     //console.log(id_)
    //     //window.location.hash = aboutBlockName;

    // }

    ///////////////////////////////////////


    // const [cards, setCards] = useState([]);
    // useEffect(() => {
    //     albumDataPromise.then((value) => {
    //         for (let element of value) {
    //             preloadImage(element.cover.image_500)
    //         }
    //         setCards(
    //             value
    //         );
    //     })
    // }, []);

    const [cards, setCards] = useState();
    useEffect(() => {
        albumDataPromise.then((res) => {
            setCards(res)
        })
    }, [])

    const [tagsData, setTagsData] = useState();
    useEffect(() => {
        tagsDataPromise.then((res) => {
            setTagsData(res)
        })
    }, [])
    const [industriesData, setIndustriesData] = useState();
    useEffect(() => {
        industriesDataPromise.then((res) => {
            setIndustriesData(res)
        })
    }, [])


    const project_link = BACKEND_LINK + project_api_call_link.replace('#{project_id}', currentProjectId) // TODO move to portfolio
    const [projectData, setProjectData] = useState({});
    useEffect(() => {
        getDataFromApiCall(project_link)
            .then(res => {
                setProjectData(res)
            })
    }, [project_link])


    return (
        <React.Fragment>
            <CssBaseline />

            <NavBar onClickMe={toAbout} onClickAbout={toAbout} onClickPortfolio={toProjects} />
            <main className={classes.main}>
                <Fade
                    in={currentBlockName === aboutBlockName} onExited={() => { onBlockFadeExited(aboutBlockName) }}
                    mountOnEnter={true} unmountOnExit={true} timeout={fadeTime}
                >
                    <Container className={classes.cardAbout} maxWidth={matches ? "md" : ""}>
                        <AboutUnit />
                    </Container>
                </Fade>

                <Fade
                    in={currentBlockName === projectsListBlockName} onExited={() => { onBlockFadeExited(projectsListBlockName) }}
                    mountOnEnter={true} unmountOnExit={true} timeout={fadeTime}
                >
                    <Container maxWidth={matches ? "md" : ""} >
                        <Box className={classes.tagsPanel}>
                            <TagsPanel onClickCallback={filterProjectsByTagStartTransition} showAllTagId={showAllTagId} nextFilteringTagId={nextFilteringTagId}
                                tagsData={tagsData} industriesData={industriesData}
                            />
                        </Box>
                        <Divider />
                        <Box className={classes.smallCardsAlbum}>
                            <Fade
                                in={currentFilteringTagId === nextFilteringTagId} onExited={() => { onfilterProjectsByTagTransitionExited() }}
                                mountOnEnter={true} unmountOnExit={true} timeout={fadeTime}
                            >
                                <Box>
                                    <SmallCardsAlbum onClickCallback={toProject} cards={cards} tagFilteringId={currentFilteringTagId} showAllTagId={showAllTagId} />
                                </Box>
                            </Fade>
                        </Box>
                    </Container>
                </Fade>
                <Fade
                    in={(currentBlockName !== null) && currentBlockName === projectBlockName} onExited={() => { onBlockFadeExited(projectBlockName) }}
                    mountOnEnter={true} unmountOnExit={true} timeout={fadeTime}
                >
                    <Container className={classes.projectConteiner} maxWidth={matches ? "md" : ""}>
                        <Project projectData={projectData} />
                    </Container>
                </Fade>
            </main>
            <Fade
                in={(currentBlockName !== null) && (currentFilteringTagId === nextFilteringTagId)}
                mountOnEnter={true} unmountOnExit={true} timeout={fadeTime}
            >
                <footer className={classes.footer}>
                    <Copyright />
                </footer>
            </Fade>
        </React.Fragment >
    );
}