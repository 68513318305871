import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { Chip } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider';
import { border } from '@material-ui/system';
import { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';

import Tag from './tag';
import Skill from './skill';


import { callbackWithArgsHandler } from '../utils'
import { getDataFromApiCall as getDataFromApiCall } from '../utils'
import { LocationSearchingOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    tagConteiner: {
        margin: theme.spacing(0, 0, 0),
        padding: theme.spacing(0, 0, 0),
    },
    tagGrid: {
        justifyContent: "space-around",
        margin: theme.spacing(0, 0, 0),
        padding: theme.spacing(0, 0, 0),
    },
    divider: {
        margin: theme.spacing(0, 0, 1),
        padding: theme.spacing(0, 0, 0),
    },
    dividerWithMargin: {
        margin: theme.spacing(3, 0, 3),
    },
    tagIndustryGridItem: {
        margin: theme.spacing(0, 0, 0),
    },
}));



export default function SkillsPanel(props) {
    const classes = useStyles();

    const [matches, setmatches] = useState(window.matchMedia("(min-width: 500px)").matches);
    useEffect(() => {
        const handler = e => setmatches({ matches: e.matches });
        window.matchMedia("(min-width: 500px)").addListener(handler);
    });

    const bigH = "h5"
    const smallH = "h6"

    const spacingWithRaiting = 0
    const spacingNoRaiting = 0

    return (
        <Box>
            <Grid container spacing={4}>
                <Grid item sm={4} className={classes.tagIndustryGridItem}>
                    <Grid container spacing={1} className={classes.tagGrid}>
                        <Typography gutterBottom variant={matches ? bigH : smallH}>
                            Game Dev
                        </Typography>
                    </Grid>
                    <Grid container spacing={spacingWithRaiting} className={classes.tagGrid} sm={12}>
                        <Skill name="Unity" value={-1} />
                        <Skill name="C#" value={-1} />
                        <Skill name="AR" value={-1} />
                        <Skill name="VR" value={-1} />
                    </Grid>

                    <Divider className={classes.divider} />
                    <Grid container spacing={spacingNoRaiting} className={classes.tagGrid} sm={12}>
                        <Skill name="Vuforia" value={-1} />
                        <Skill name="EasyAR" value={-1} />
                    </Grid>
                </Grid>

                <Grid item sm={4} className={classes.tagIndustryGridItem}>
                    <Grid container spacing={1} className={classes.tagGrid}>
                        <Typography gutterBottom variant={matches ? bigH : smallH}>
                            ML / BackEnd
                        </Typography>
                    </Grid>
                    <Grid container spacing={spacingWithRaiting} className={classes.tagGrid} sm={12}>
                        <Skill name="Python" value={-1} />
                        <Skill name="ML" value={-1} />
                        <Skill name="FastAPI" value={-1} />
                        <Skill name="Microservices" value={-1} />
                        <Skill name="Web Scraping" value={-1} />
                        <Skill name="SQL" value={-1} />
                    </Grid>

                    <Divider className={classes.divider} />
                    <Grid container spacing={spacingNoRaiting} className={classes.tagGrid} sm={12}>
                        <Skill name="Keras" value={-1} />
                        <Skill name="TensorFlow" value={-1} />
                        <Skill name="Imgaug" value={-1} />
                        <Skill name="MaskRCNN" value={-1} />
                        <Skill name="UNet" value={-1} />
                        <Skill name="DeepLab" value={-1} />
                    </Grid>
                    <Divider className={classes.divider} />
                    <Grid container spacing={spacingNoRaiting} className={classes.tagGrid} sm={12}>
                        <Skill name="Tesseract" value={-1} />
                        <Skill name="Sklearm" value={-1} />
                        <Skill name="LightGBM" value={-1} />
                        <Skill name="H2O" value={-1} />
                        <Skill name="TPOT" value={-1} />
                    </Grid>
                    <Divider className={classes.divider} />
                    <Grid container spacing={spacingNoRaiting} className={classes.tagGrid} sm={12}>
                        <Skill name="Numpy" value={-1} />
                        <Skill name="Pandas" value={-1} />
                        <Skill name="Matplotlib" value={-1} />
                    </Grid>
                    <Divider className={classes.divider} />
                    <Grid container spacing={spacingNoRaiting} className={classes.tagGrid} sm={12}>
                        <Skill name="Celary" value={-1} />
                        <Skill name="RabbitMQ" value={-1} />
                    </Grid>
                </Grid>

                <Grid item sm={4} className={classes.tagIndustryGridItem}>
                    <Grid container spacing={1} className={classes.tagGrid}>
                        <Typography gutterBottom variant={matches ? bigH : smallH}>
                            FrontEnd
                        </Typography>
                    </Grid>
                    <Grid container spacing={spacingWithRaiting} className={classes.tagGrid} sm={12}>
                        <Skill name="JavaScript" value={-1} />
                        <Skill name="ReactJS" value={-1} />
                        <Skill name="WebExtensions" value={-1} />
                        <Skill name="HTML/CSS" value={-1} />
                    </Grid>
                </Grid>
            </Grid >

            <Divider className={classes.dividerWithMargin} />
            <Grid container spacing={4} style={{ justifyContent: "center" }}>
                <Grid item sm={6} className={classes.tagIndustryGridItem}>
                    <Grid container spacing={1} className={classes.tagGrid}>
                        <Typography gutterBottom variant={matches ? bigH : smallH}>
                            Other
                        </Typography>
                    </Grid>
                    <Grid container spacing={spacingWithRaiting} className={classes.tagGrid} sm={12}>
                        <Skill name="Git" value={-1} />
                        <Skill name="Photoshop" value={-1} />
                        <Skill name="Blender3D" value={-1} />
                        <Skill name="FL Studio" value={-1} />
                        <Skill name="Audacity" value={-1} />
                    </Grid>
                </Grid>
                <Grid item sm={6} className={classes.tagIndustryGridItem} style={{ width: "100%" }}>
                    <Grid container spacing={1} className={classes.tagGrid}>
                        <Typography gutterBottom variant={matches ? bigH : smallH}>
                            Languages
                        </Typography>
                    </Grid>
                    <Grid container spacing={0} className={classes.tagGrid} sm={12} >
                        <Skill name="Russian - Native" value={-1} />
                        <Skill name="English - B1" value={-1} />
                    </Grid>
                </Grid>
            </Grid>
        </Box >
    )
}
