import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Markdown from './markdown';
import { useEffect, useState, useLayoutEffect } from 'react';
import ProjectGallery from './projectGallery';
// import ReactPlayer from 'react-player/file'
import ReactPlayer from 'react-player/youtube'

import JsxParser from "react-jsx-parser";

import Skeleton from '@material-ui/lab/Skeleton';
import Card from '@material-ui/core/Card';
import { getBackEndLink } from './portfolio';

import StandardContainer from './standardContainer';

import { getDataFromApiCall } from './../utils'
import { addStringFormat } from './../utils'
addStringFormat()

const useStyles = makeStyles((theme) => ({
    markdown: {
        ...theme.typography.body2,
        padding: theme.spacing(3, 0),
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 0,
    },
    gridSuperCont: {
        padding: theme.spacing(10, 0),
        paddingBottom: 0,
    },
    jsxParser: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 0,
    },
    contentSuperCont: {
        padding: theme.spacing(3, 0, 3),
    },
    contentStandardCont: {
        padding: theme.spacing(30, 0, 30),
    },
}));


function ContentSkeleton(matches) {
    return (
        <Box>
            <StandardContainer>
                <Typography variant={matches ? "h3" : "h5"} gutterBottom>
                    <Skeleton width="45%" />
                </Typography>

                <Typography variant={matches ? "h5" : "h7"} gutterBottom>
                    <Skeleton width="97%" />
                </Typography>
                <Typography variant={matches ? "h5" : "h7"} gutterBottom>
                    <Skeleton width="90%" />
                </Typography>
                <Typography variant={matches ? "h5" : "h7"} gutterBottom>
                    <Skeleton width="100%" />
                </Typography>
                <Typography variant={matches ? "h5" : "h7"} gutterBottom>
                    <Skeleton width="80%" />
                </Typography>
                <Typography variant={matches ? "h5" : "h7"} gutterBottom>
                    <Skeleton width="99%" />
                </Typography>
                <Typography variant={matches ? "h5" : "h7"} gutterBottom>
                    <Skeleton width="40%" />
                </Typography>
            </StandardContainer>
            <StandardContainer>
                <Typography variant={matches ? "h3" : "h5"} gutterBottom>
                    <Skeleton width="40%" />
                </Typography>

                <Typography variant={matches ? "h5" : "h7"} gutterBottom>
                    <Skeleton width="20%" />
                </Typography>
                <Typography variant={matches ? "h5" : "h7"} gutterBottom>
                    <Skeleton width="19%" />
                </Typography>
                <Typography variant={matches ? "h5" : "h7"} gutterBottom>
                    <Skeleton width="21%" />
                </Typography>
            </StandardContainer>
        </Box>
    )
}

export default function Project(props) {
    const classes = useStyles();
    const { projectData } = props;

    const [matches, setmatches] = useState(window.matchMedia("(min-width: 500px)").matches);
    useEffect(() => {
        const handler = e => setmatches({ matches: e.matches });
        window.matchMedia("(min-width: 500px)").addListener(handler);
    });

    window.scrollTo(0, 0);

    return (
        <Grid conteiner className={classes.gridSuperCont} xs={12}>
            <Grid item>
                <Box item xs={12}>
                {projectData && projectData.content ? 
                    <Typography variant={matches ? "h3" : "h5"} gutterBottom>
                        {projectData.name}
                    </Typography>
                    :
                    <Typography variant={matches ? "h3" : "h5"} gutterBottom>
                        <Skeleton width="65%" />
                    </Typography>
                }
                </Box>
                <ProjectGallery images={projectData ? projectData.images_gallery : null} />
                {projectData && projectData.video ? (
                    <ReactPlayer url={projectData.video} controls={true} width={"100%"} height={"400px"} />
                ) : null}
                <Box item xs={12} className={classes.contentSuperCont}>
                    {projectData && projectData.content ? (
                        <JsxParser
                            bindings={{ "matches": matches }}
                            components={{ Typography, Box, StandardContainer }}
                            jsx={(String.format(projectData.content, ...(projectData.images_other.map((x) => x.image))))}
                        />) : ContentSkeleton(matches)}
                </Box>
            </Grid>
        </Grid>
    )

    // return projectData.content ? renderProject(projectData, classes, matches) : null
    //return renderProject(projectData, classes, matches, onLoaded)
}

{/* <Box item xs={12}>
    <StandardContainer>
        <Box>
            <Typography gutterBottom variant={matches ? "h3" : "h5"}>About this project</Typography>
            <Typography inline variant={matches ? "h5" : "h7"}>
                <p>
                    It was one of my first professional projects. The customer (Döсke) made a calendar with painted pictures for its VIP clients and wanted me to animate them with the augmented reality technology.
                    So I hire artist to create a beautiful animation for each picture and created app for Android and iOS using Vuforia AR framework.
                </p>
                <p>
                    The main challenge of the project was a necessity to keep  resolution of the animations high and the app size small to fit requirements of the customer and app stores. The first idea was to convert animation frames into a high compression video format but that was a wrong way because of the low unpacking speed of the videos by some smartphones. That caused a lag when a user targeted an AR marker with his device. So I had to implement my own animation player with double bufferisation and delta-compressed image frames and this approach was successful.
                </p>
            </Typography>
        </Box>
    </StandardContainer >

    <StandardContainer >
        <Box>
            <Typography gutterBottom variant={matches ? "h3" : "h5"}>Technical Sheet</Typography>
            <ul>
                <li style={matches ? "font-size:25px" : "font-size:15px"} >
                    <Typography display="inline" variant={matches ? "h5" : "h7"}>
                        C#
                    </Typography>
                </li>
                <li style={matches ? "font-size:25px" : "font-size:15px"} >
                    <Typography display="inline" variant={matches ? "h5" : "h7"}>
                        Unity3D
                    </Typography>
                </li>
                <li style={matches ? "font-size:25px" : "font-size:15px"} >
                    <Typography display="inline" variant={matches ? "h5" : "h7"}>
                        AR
                    </Typography>
                </li>
                <li style={matches ? "font-size:25px" : "font-size:15px"} >
                    <Typography display="inline" variant={matches ? "h5" : "h7"}>
                        Vuforia
                    </Typography>
                </li>
            </ul>
        </Box>
    </StandardContainer >
</Box > */}