import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { Chip } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider';
import HeroUnit from './heroUnit';
import SkillsPanel from './skillsPanel';
import Box from '@material-ui/core/Box';
import { useEffect, useState } from 'react';


const useStyles = makeStyles((theme) => ({
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 0,
        padding: !theme.spacing(0, 0, 0),
    },
    cardContentPaddingOff: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 0,
        padding: 0
    }
}));

export default function SkillsUnit(props) {
    const classes = useStyles();
    const [matches, setmatches] = useState(window.matchMedia("(min-width: 500px)").matches);
    useEffect(() => {
        const handler = e => setmatches({ matches: e.matches });
        window.matchMedia("(min-width: 500px)").addListener(handler);
    });


    return (
        <Box>
            {/* <CardContent className={matches ? classes.cardContent : classes.cardContentPaddingOff}> */}
            <Typography gutterBottom variant={matches ? "h3" : "h5"}>
                Skills
            </Typography>
            <SkillsPanel />
            {/* </CardContent> */}
        </Box>
    );
}
