import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import Grid from '@material-ui/core/Grid';
import { useEffect, useState } from 'react';

import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    tagGrid: {
        justifyContent: "space-around",

    },

    card: {
        margin: theme.spacing(0, 0, 1),
        padding: theme.spacing(0, 0, 0),
    },

    cardNoRating: {
        //margin: theme.spacing(0, 0.25, 0.5),
        //padding: theme.spacing(0, 0, 0),
        marginBottom: 5,
        marginRight: 10,
    },

    cardConteiner: {


    },
}));

const StyledRating = withStyles({
    iconFilled: {
        color: "#2986cc" // TODO to db
    },
})(Rating);

export default function Skill(props) {
    const classes = useStyles();
    const name = props.name
    const value = props.value
    const renderRating = value > 0

    // const [matches, setmatches] = useState(window.matchMedia("(min-width: 500px)").matches);
    // useEffect(() => {
    //     const handler = e => setmatches({ matches: e.matches });
    //     window.matchMedia("(min-width: 500px)").addListener(handler);
    // });

    return (
        <Card className={renderRating === true ? classes.card : classes.cardNoRating} elevation={0}>
            <Typography align="center" component="legend">{name}</Typography>
            {renderRating && <StyledRating value={value} precision={0.5} readOnly />}
        </Card>
    );
}

