import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';


const useStyles = makeStyles((theme) => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 0,
    },
    superConteiner: {
        padding: theme.spacing(0, 0, 3),
    },
    divider: {
        margin: theme.spacing(0, 0, 3),
        padding: theme.spacing(0, 0, 0),
    }
}));

export default function StandardContainer(props) {
    const classes = useStyles();
    // const [matches, setmatches] = useState(window.matchMedia("(min-width: 500px)").matches);
    // useEffect(() => {
    //     const handler = e => setmatches({ matches: e.matches });
    //     window.matchMedia("(min-width: 500px)").addListener(handler);
    // });

    return (
        <div className={classes.superConteiner}>
            {props.noTopDivider ? (null) : (
                <Divider className={classes.divider} />
            )}
            <Paper className={classes.card} elevation={0}>
                <Box>
                    {/* {React.cloneElement(props.children, { matches: matches })} */}
                    {props.children}
                </Box>
            </Paper >
        </div >
    );
};
