export async function getDataFromApiCall(link) {
    let responce = await fetch(link)
    let data = await responce.json()
    return data;
}

export function preloadImage(url) {
    var img = new Image();
    img.src = url;
    return (img)
}

export function callbackWithArgsHandler(callback, ...args) {
    return () => {
        callback(...args)
    }
}

export function addStringFormat() {
    if (!String.format) {
        String.format = function (format) {
            var args = Array.prototype.slice.call(arguments, 1);
            return format.replace(/{=(\d+)=}/g, function (match, number) {
                return typeof args[number] != 'undefined'
                    ? args[number]
                    : match
                    ;
            });
        };
    }
}
