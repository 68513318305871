import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ButtonBase from '@material-ui/core/ButtonBase';
import Tag from './tag';
import TagSkeleton from './tag';

import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({

    cardConteiner: {
        margin: theme.spacing(0, 0, 7),
        transition: "transform 0.15s ease-in-out",
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 0,

        transition: "transform 0.15s ease-in-out",
        "&:hover": {
            transform: "scale3d(1.05, 1.05, 1)",
            boxShadow: theme.shadows[7],
        },
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 0,
    },
    cardButton: {
        display: "block",
        textAlign: "initial"
    },
    cardActions: {
        zIndex: 2000,
    },

    tagsGrid: {
        margin: theme.spacing(0, 1, 1),
    },
}));

<Grid container spacing={1}>
    <Grid item xs={4}>
        <Skeleton variant="rectangular" height={220} />
    </Grid>
    <Grid item xs={4}>
        <Skeleton variant="rectangular" height={220} />
    </Grid>
    <Grid item xs={4}>
        <Skeleton variant="rectangular" height={220} />
    </Grid>
</Grid>

function ProjectCardSmallSkeleton() {
    const classes = useStyles();
    return (
        <Box>
            <Skeleton variant="rectangular" width={408} height={229.5} />
            <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5" component="h2">
                    <Skeleton width="90%" />
                </Typography>

                <Typography>
                    <Skeleton width="78%" />
                </Typography>
                <Typography>
                    <Skeleton width="85%" />
                </Typography>
                <Typography>
                    <Skeleton width="20%" />
                </Typography>
            </CardContent>


            <Grid container item spacing={1} className={classes.tagsGrid} alignContent="stretch">
                <Grid item>
                    <Tag skeleton={true} width={75} />
                </Grid>
                <Grid item>
                    <Tag skeleton={true} width={169} />
                </Grid>
            </Grid>
        </Box>
    )
}


export default function ProjectCardSmall(props) {
    const classes = useStyles();
    const card = props.card
    const tags = card ? card.tags : []
    const showAllTagId = props.showAllTagId

    return (
        <Container className={classes.cardConteiner}>
            <Card className={classes.card} elevation={3}>
                {!props.skeleton ?
                    (
                        <ButtonBase
                            link={"#" + card.name}
                            className={classes.cardButton}
                            onClick={props.onClickCallback}
                        >

                            <CardMedia
                                className={classes.cardMedia}
                                image={card.cover ? card.cover.image_500 : null}
                                title={card.name}
                            />

                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {card.name}
                                </Typography>
                                <Typography>
                                    {card.short_description}
                                </Typography>
                            </CardContent>


                            <Grid container item spacing={1} className={classes.tagsGrid} alignContent="stretch">
                                {tags.sort(function (a, b) { return a.priority - b.priority; }).map((tag, tagIndex) => (
                                    <Grid item key={tagIndex}>
                                        <Tag tagData={tag} showAllTagId />
                                    </Grid>
                                ))}
                            </Grid>
                        </ButtonBase>
                    ) : (
                        ProjectCardSmallSkeleton()
                    )}
            </Card>
        </Container >
    );
}
