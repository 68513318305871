import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';

import PropTypes from 'prop-types';

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import { useEffect, useState } from 'react';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';

import Link from '@material-ui/core/Link';

import avatarImg from '../media/images/avatar.png'
import avatarSmallImg from '../media/images/avatarSmall.png'


const useStyles = makeStyles((theme) => ({
    heroSuperConteiner: {
        padding: theme.spacing(3, 0, 3),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(10, 0, 6),

        borderBottom: `1px solid ${theme.palette.divider}`
    },
    avatarCardCont: {
        margin: theme.spacing(0, 0, 0),
    },
    avatarLarge: {
        width: theme.spacing(24),
        height: theme.spacing(24),
    },
    linkCustom: {
        color: theme.palette.text.secondary,
        //color: "#0096cc",
        // "&:hover": {
        //     color: "#0074d9"
        // },
    },
    nameTypography: {
        //fontSize: "5vw"
    },

    avatarGrigCont: {
        display: "flex",
        alignItems: "center",
        margin: theme.spacing(2, 2, 2),
    },
    textCardCont: {
        display: "flex",
        alignItems: "center",
    },

    skilsTypographyBig: {

    },
    skilsTypographySmall: {
        fontSize: "3.3vw"
    },
}));

HeroUnit.propTypes = {
    loading: PropTypes.bool,
};

export default function HeroUnit(props) {
    const classes = useStyles();

    const [matches, setmatches] = useState(window.matchMedia("(min-width: 768px)").matches);
    useEffect(() => {
        const handler = e => setmatches({ matches: e.matches });
        window.matchMedia("(min-width: 768px)").addListener(handler);
    });


    const [avatarSmallLoaded, setAvatarSmallLoaded] = useState(false);
    var image = new Image();
    image.src = avatarSmallImg;
    image.addEventListener('load', function () {
        setAvatarSmallLoaded(true)
    });


    const [avatarLoaded, setAvatarLoaded] = useState(false);
    var image = new Image();
    image.src = avatarImg;
    image.addEventListener('load', function () {
        setAvatarLoaded(true)
    });


    return (
        <div className={classes.heroSuperConteiner}>
            <div className={classes.heroContent}>
                <Container maxWidth="md">
                    <Grid container direction={'row'} spacing={1}>
                        <Grid container xl={3} md={3} sm={12} xs={12} justifyContent="center" className={classes.avatarGrigCont}>
                            <Card elevation={0} className={classes.avatarCardCont}>
                                <Zoom>
                                    {avatarLoaded ? (
                                        <Avatar src={avatarImg} alt={"Eugene Kuklin"} className={classes.avatarLarge} />
                                    ) : (
                                        avatarSmallLoaded ? (
                                            <Avatar src={avatarSmallImg} alt={"Eugene Kuklin"} className={classes.avatarLarge} />
                                        ) : (
                                            <Skeleton variant="circle" className={classes.avatarLarge} />
                                        )
                                    )}
                                </Zoom>
                            </Card>
                        </Grid>
                        <Grid item xl={0} md={0} sm={0} xs={0} justifyContent="center" />
                        <Grid container xl={8} md={8} sm={12} xs={12} justifyContent="center" alignContent="center" alignItems="center">
                            <Card elevation={0}>
                                <Grid item direction={'column'} justifyContent="center" className={classes.textCardCont}>
                                    <Grid container direction={'column'} justifyContent="center">
                                        <Typography className={classes.nameTypography} variant={matches ? "h2" : "h4"} align="center" color="textPrimary" gutterBottom>
                                            Eugene Kuklin
                                        </Typography>
                                    </Grid>
                                    <Typography className={matches ? "" : classes.skilsTypographySmall} variant="h5" align="center" color="textSecondary">
                                        Python | ML | CV | FastAPI | Microservices
                                    </Typography>
                                    <Typography className={matches ? "" : classes.skilsTypographySmall} variant="h5" align="center" color="textSecondary">
                                        C# | Unity3D | Augmented Reality | Virtual Reality
                                    </Typography>
                                    <Typography className={matches ? "" : classes.skilsTypographySmall} variant="h5" align="center" color="textSecondary">
                                        JavaScript | ReactJS | WebExtensions
                                    </Typography>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </div >
        </div >
    )
}