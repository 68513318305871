import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { Chip } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider';
import HeroUnit from './heroUnit';
import SkillsUnit from './skillsUnit';
import { useEffect, useState } from 'react';
import Markdown from './markdown';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
    cardConteiner: {
        margin: theme.spacing(0, 0, 3),
    },
    cardConteinerSpacingOff: {
        margin: theme.spacing(0, 0, 0),
    },

    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 0,
    },
    cardMedia: {
        //paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 0,
    },
    cardContentPaddingOff: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 0,
        padding: 0
    },
    cardButton: {
        display: "block",
        textAlign: "initial"
    },
    cardActions: {
        zIndex: 2000,
    },
    cardTypography: {
        //fontSize: 20,
        //display: 'inline-block',
        whiteSpace: 'pre-line'
    },
    markdown: {
        ...theme.typography.body2,
        padding: theme.spacing(3, 0),
    },
}));

export default function DescriptionUnit(props) {
    const classes = useStyles();

    // TODO move data to DB
    const card = {
        "title": "About me",
        "description": `\tI am an experienced  Python, Machine Learning and Computer Vision developer. 
        I have been running a small research group on machine learning since 2019.
        Also I have experience in Game Development with Unity3D, especially in developing mobile applications with augmented reality.`
    }

    const [matches, setmatches] = useState(window.matchMedia("(min-width: 500px)").matches);
    useEffect(() => {
        const handler = e => setmatches({ matches: e.matches });
        window.matchMedia("(min-width: 500px)").addListener(handler);
    });



    return (
        <Box>
            <Typography gutterBottom variant={matches ? "h3" : "h5"}>
                {card.title}
            </Typography>
            <Typography display="inline" variant={matches ? "h5" : "h7"} className={classes.cardTypography}>
                {card.description}
            </Typography>
        </Box>
    );
}
