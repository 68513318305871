import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { Chip } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider';
import { border } from '@material-ui/system';
import { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';

import Tag from './tag';
import { getBackEndLink } from './portfolio';


import { callbackWithArgsHandler } from './../utils'
import { getDataFromApiCall as getDataFromApiCall } from './../utils'
import { LocationSearchingOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    tagConteiner: {
        margin: theme.spacing(3, 1, 0),
    },
    tagGrid: {
        justifyContent: "center",
        padding: theme.spacing(0, 0, 3),
    },
    showAllGrid: {
        justifyContent: "center",
        padding: theme.spacing(0, 0, 3),
    },
    cardButton: {
        transition: "transform 0.15s ease-in-out",
        "&:hover": {
            transform: "scale3d(1.05, 1.05, 1)",
            boxShadow: theme.shadows[4],
        },
    },
    tagIndustryGridItem: {
        outline: "8px rgba(170, 50, 220, .6)",
        borderRadius: "50px!important",
    },
}));



{/* <Skeleton variant="text" />
<Skeleton variant="circular" width={40} height={40} />
<Skeleton variant="rectangular" width={210} height={118} /> */}

function TagsPanelSkeleton(classes) {

    return (
        <Box>
            <Grid container className={classes.showAllGrid} >
                <Tag skeleton={true} width={90} />
            </Grid>

            <Grid container spacing={1}>
                <Grid item sm={4} className={classes.tagIndustryGridItem}>
                    <Grid container spacing={1} className={classes.tagGrid}>
                        <Tag skeleton={true} width={39} />
                        <Tag skeleton={true} width={84} />
                        <Tag skeleton={true} width={179} />
                        <Tag skeleton={true} width={135} />
                    </Grid>
                </Grid>
                <Grid item sm={4} className={classes.tagIndustryGridItem}>
                    <Grid container spacing={1} className={classes.tagGrid}>
                        <Tag skeleton={true} width={75} />
                        <Tag skeleton={true} width={169} />
                        <Tag skeleton={true} width={158} />
                        <Tag skeleton={true} width={76} />
                        <Tag skeleton={true} width={136} />
                    </Grid>
                </Grid>
                <Grid item sm={4} className={classes.tagIndustryGridItem}>
                    <Grid container spacing={1} className={classes.tagGrid}>
                        <Tag skeleton={true} width={105} />
                        <Tag skeleton={true} width={88} />
                        <Tag skeleton={true} width={155} />
                    </Grid>
                </Grid>
            </Grid >
        </Box >
    )
}

function renderTagsPanel(tagsDataIn, industriesDataIn, onClickCallback, classes, showAllTagId, nextFilteringTagId) {

    if (tagsDataIn) {
        var showAllTagIndex = tagsDataIn.map((tag) => tag.id).indexOf(showAllTagId)
        var showAllTag = tagsDataIn[showAllTagIndex]
    }
    if (industriesDataIn) {
        var industriesData = industriesDataIn.map(industry => industry)
        industriesData.sort(function (a, b) {
            return a.priority - b.priority;
        })
    }

    return (
        < Container className={classes.tagConteiner} >
            {tagsDataIn && industriesData ? (
                <Box>
                    <Grid container className={classes.showAllGrid} >
                        <Grid item key={showAllTag.id}>
                            <ButtonBase
                                className={classes.cardButton}
                                onClick={callbackWithArgsHandler(onClickCallback, showAllTag.id)}
                            >
                                {showAllTag.isActive = (showAllTag.id === nextFilteringTagId)}
                                <Tag tagData={showAllTag} />
                            </ButtonBase>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        {industriesData.map((industry, industryIndex) => (
                            <Grid item key={industryIndex} sm={parseInt(12 / industriesData.length)} className={classes.tagIndustryGridItem}>
                                <Grid container spacing={1} className={classes.tagGrid}>
                                    {industry.tags_list.sort(function (a, b) { return a.priority - b.priority; }).map((tag, tagIndex) => (
                                        <Grid item key={tagIndex}>
                                            <ButtonBase
                                                className={classes.cardButton}
                                                onClick={callbackWithArgsHandler(onClickCallback, tag.id)}
                                            >
                                                {tag.isActive = (tag.id === nextFilteringTagId)}
                                                <Tag tagData={tag} />
                                            </ButtonBase>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        ))}
                    </Grid >
                </Box>
            ) : TagsPanelSkeleton(classes)}

        </Container >
    )
}

export default function TagsPanel(props) {
    const classes = useStyles();
    const { showAllTagId, tagsData, industriesData } = props

    return renderTagsPanel(tagsData, industriesData, props.onClickCallback, classes, showAllTagId, props.nextFilteringTagId)
}
