import React from 'react';
import usePromise from 'react-promise';
import { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { Chip } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import ProjectCardBig from './projectCardBig';
import ProjectCardSmall from './projectCardSmall';
import ProjectCardSmallSkeleton from './projectCardSmall';
import NavButton from './navButton';
import NavBar from './navBar';
import HeroUnit from './heroUnit';
import AboutUnit from './aboutUnit';
import Project from './project';

import { callbackWithArgsHandler } from './../utils'


export default function SmallCardsAlbum(props) {
    var { onClickCallback, cards, tagFilteringId, showAllTagId } = props;
    // const { cards, loading } = usePromise<CardsAlbum>(prom)


    function renderTagsPanel(cards, onClickCallback, tagFilteringId, showAllTagId) {
        let filtredCards = cards
        if (tagFilteringId !== showAllTagId) {
            filtredCards = cards.filter(card => card.tags.map(tag => tag.id).includes(tagFilteringId))
        }
        return (
            <Grid container spacing={0}>
                {cards ? (
                    filtredCards.map((card) => (
                        <Grid item key={card.id} sm={6}>
                            <ProjectCardSmall card={card} onClickCallback={callbackWithArgsHandler(onClickCallback, card.id)} />
                        </Grid >
                    ))
                ) : (
                    [...Array(8).keys()]).map(() => (

                        <Grid item sm={6}>
                            <ProjectCardSmall skeleton={true} />
                        </Grid >
                    )
                    )}

            </Grid >
        )
    }

    return renderTagsPanel(cards, onClickCallback, tagFilteringId, showAllTagId)
}