import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { Chip } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
    tagCard: {
        //maxWidth: 10000,
        minWidth: 0,
        maxHeight: 30,
        minHeight: 30,
        //borderRadius: 0,
        backgroundColor: "#000000" // TODO to db
    },
    tagCardActive: {
        backgroundColor: "#2986cc" // TODO to db
    },
    cardMedia: {
        // marginTop: 1,
        // marginLeft: 5,
        paddingTop: '100%', // 16:9
        display: "flex",
        alignItems: "center",
        maxWidth: "100%",
        minWidth: "100%",
    },
    tagTypographyGrigCont: {
        marginTop: 2,
        paddingLeft: 4,
        paddingRight: 4,
        alignItems: "center",
        verticalAlign: "center",
    },
    tagImageGrigCont: {
        alignItems: "center",
        verticalAlign: "center",

        margin: 5,
        marginTop: 4,
        marginLeft: 0,

        maxWidth: 22,
        minWidth: 22,
        maxHeight: 22,
        minHeight: 22,
    },
    tagTypography: {
        paddingTop: 0,
        paddingLeft: 0,
        marginLeft: 3,
        marginRight: 4,

        fontSize: 19,
        color: "#ffffff" // TODO to db
    }
}));

function TagSkeleton(width) {
    const skelHeight = 30
    const skelVariant = "rectangle"
    const skelStyle = { "border-radius": 3, "margin": 3 }
    return (
        <Grid item>
            <Skeleton variant={skelVariant} style={skelStyle} width={width} height={skelHeight} />
        </Grid>
    )
}

export default function Tag(props) {
    const classes = useStyles();
    const tag = props.tagData ? props.tagData : null


    return (
        <Box>
            {!props.skeleton ?
                (
                    <Card elevation={3} className={!tag.isActive ? classes.tagCard : classes.tagCard + " " + classes.tagCardActive}>
                        <Grid container item spacing={0} className={classes.avatarGrigCont} alignContent="space-around">
                            <Box item flex={1} display="flex" className={classes.tagTypographyGrigCont} alignContent="center" justifyContent="center" spacing={0}>
                                <Typography gutterBottom className={classes.tagTypography}>
                                    {tag.show_name ? tag.name : ""}
                                </Typography>
                            </Box >
                            {(tag.icon !== null && tag.show_icon) ? (
                                <Box height="1000" width="1000" display="flex" item className={classes.tagImageGrigCont} alignContent="center" justifyContent="center" spacing={0}>
                                    <CardMedia
                                        className={classes.cardMedia}
                                        image={tag.icon}
                                        title={tag.name}
                                    />
                                </Box >
                            ) : null}

                        </Grid>
                    </Card>
                ) : (TagSkeleton(props.width))
            }
        </Box>
    );
}

