import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';



const useStyles = makeStyles((theme) => ({
    cardConteiner: {
        margin: theme.spacing(0, 0, 3),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 0,
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 0,
    },
    cardContentPaddingOff: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 0,
        padding: 0
    },
    cardTypography: {
        //fontSize: 20,
        //display: 'inline-block',
        whiteSpace: 'pre-line'
    },

    superConteiner: {
        padding: theme.spacing(0, 0, 1),
    },
    content: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 0, 0),

        borderTop: `1px solid ${theme.palette.divider}`
    },
    divider: {
        margin: theme.spacing(0, 0, 3),
        padding: theme.spacing(0, 0, 0),
    },
    p: {
        textIntent: 20
    }
}));

export default function ExperienceUnit(props) {
    const classes = useStyles();
    const [matches, setmatches] = useState(window.matchMedia("(min-width: 500px)").matches);
    useEffect(() => {
        const handler = e => setmatches({ matches: e.matches });
        window.matchMedia("(min-width: 500px)").addListener(handler);
    });


    const steps = [
        {
            label: 'ML Teem Lead / Python Developer',
            period: '📅 2019 - Ongoing',
            description: (<div>
                <div>
                    I am running a small research group on machine learning. We are working on computer vision problems
                    of multispectral satellite imagery segmentation and object detection. We also do the backend part.
                </div>
                {/* <ul> */}
                <div>
                    Sentinel-2 imagery:
                </div>
                <ul type={'Disc'}>
                    <li>Forest semantic segmentation for area changing detection (UNet, DeepLab, my own trasformer-like CNN)</li> {/*  style={{ "content": "•", "font-size": "24px", "vertical-align": "top", "padding-right": "7px" }} */}
                    <li>Agricultural fields instance segmentation for vegetation indices calculation (DeepLab/WaterShared, MaskRCNN)</li>
                    <li>Clouds and shadows of clauds semantic segmentation (UNet, DeepLab)</li>
                </ul>
                {"\n"}
                Drones and Google Maps imagery:
                <ul type={'Disc'}>
                    <li>House instance segmentation for tax avoidance detection (MaskRCNN)</li>
                    <li>Weed crops semantic segmentation for agricultural purposes (UNet, DeepLab)</li>
                </ul>
                {"\n"}
                Postprocessing:
                <ul type={'Disc'}>
                    <li>Solution for eliminating of intertile gap</li>
                    <li>Solution for eliminating field boundary gaps of DeepLab/WaterShared output masks</li>
                    <li>Many vector postprocessing piplines in QGIS</li>
                </ul>
                {/* </ul> */}

                Also I was making many prototypes with other ML technologies:

                <ul type={'Disc'}>
                    <li>Text recognition (OCR Tesseract)</li>
                    <li>Text classificatian with auto-ml frameworks (TPOT, H2O)</li>
                    <li>Many more</li>
                </ul>
            </div>),
        },
        {
            label: 'Unity / С# Developer',
            period: '📅 2017 - 2019',
            description:
                (<div>
                    I've made a lot of small games and advertising apps most with augmented reality (AR) and virtual reality (VR) technologies
                </div>),
        },
        {
            label: "University",
            period: '📅 2013 - 2017',
            description:
                (<div>
                    Bachelor's degree in Business Informatics (BI)
                </div>),
        },
    ];

    return (
        <Box>
            <Typography gutterBottom variant={matches ? "h3" : "h5"}>
                Experience
            </Typography>
            <Stepper orientation="vertical" style={{ padding: 0 }}>
                {steps.map((step, index) => (
                    <Step active={true} key={step.label}>
                        <Typography gutterBottom variant={matches ? "h4" : "h6"} color="textSecondary" >{step.label}</Typography>
                        <Typography gutterBottom variant={matches ? "h5" : "h7"} color="textSecondary" >{step.period}</Typography>
                        <StepContent>
                            <Typography display="inline" variant={matches ? "h5" : "h7"} className={classes.cardTypography}>{step.description}</Typography>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};