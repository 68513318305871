import React, { useState, useCallback } from "react";
import { render } from "react-dom";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

export default function ProjectGallery(props) {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    function GallerySkeleton() {
        return (
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Skeleton variant="rectangular" height={220} />
                </Grid>
                <Grid item xs={4}>
                    <Skeleton variant="rectangular" height={220} />
                </Grid>
                <Grid item xs={4}>
                    <Skeleton variant="rectangular" height={220} />
                </Grid>
            </Grid>
        )
    }


    const imagesData = props.images
    const photos = imagesData ? imagesData.map((image_data) => {
        return ({
            src: (imagesData.size > 1)? image_data.image_500 : image_data.image_1000,
            width: image_data.size[0],
            height: image_data.size[1],

            download: image_data.image,
            fullscreen: image_data.image,
            regular: image_data.image_1000,
            thumbnail: image_data.image_100
        });
    }) : null

    return (
        <Box>
            {imagesData ? (
                <Box>
                    <Gallery photos={photos} onClick={openLightbox} />
                    <ModalGateway>
                        {viewerIsOpen ? (
                            <Modal onClose={closeLightbox}>
                                <Carousel
                                    currentIndex={currentImage}
                                    views={photos.map(x => ({
                                        srcset: x.srcSet,
                                        caption: x.title,
                                        source: {
                                            download: x.download,
                                            fullscreen: x.fullscreen,
                                            regular: x.regular,
                                            thumbnail: x.thumbnail,
                                        }
                                    }))}
                                />
                            </Modal>
                        ) : null}
                    </ModalGateway>
                </Box>
            ) : GallerySkeleton()}

        </Box>
    );
}
