import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({

    cardConteiner: {
        margin: theme.spacing(0, 0, 0),

    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 0,

        transition: "transform 0.15s ease-in-out",
        "&:hover": {
            transform: "scale3d(1.05, 1.05, 1)",
            boxShadow: theme.shadows[7],
        },
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 0,
    },
    cardButton: {
        display: "block",
        textAlign: "initial"
    }
}));

export default function ProjectCardBig(props) {
    const classes = useStyles();
    const card = props.card

    return (
        <Container className={classes.cardConteiner}>
            <Card className={classes.card} elevation={3}>
                <ButtonBase
                    className={classes.cardButton}
                    onClick={props.onClickCallback}
                >
                    <CardMedia
                        className={classes.cardMedia}
                        image={card.cover}
                        title={card.name}
                    />
                    <CardContent className={classes.cardContent}>
                        <Typography gutterBottom variant="h3">
                            {card.name}
                        </Typography>
                        <Typography variant="h5">
                            {card.short_description}
                        </Typography>
                    </CardContent>
                </ButtonBase>
            </Card >
        </Container>
    );
}
